import { inject, Injectable } from '@angular/core';
import { Subscription } from '../model/subscription';
import { AuthUser } from '../model/auth-user';
import { DatePipe } from '@angular/common';
import { EnvironmentService } from './environment.service';

enum PaymentMethod {
  trainCash = 'Tren',
  onlineCard = 'Online',
  trainCard = 'Tren (card)',
  partner = 'Agentie'
}

@Injectable({
  providedIn: 'root',
})

export class UtilsService {

  private datePipe: DatePipe = inject(DatePipe);
  private envService = inject(EnvironmentService);

  // we create this because dateToIsoString is returning the UTC date time
  // which is not what we want, we want the local date time
  dateToYmd(date: Date) {
    return date.getFullYear() + '-' +
      (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
      date.getDate().toString().padStart(2, '0');
  }

  statusAsText(status: number) {
    const statusList = this.envService.getValue('status');
    switch (Number(status)) {
      case statusList.expired:
        return 'Expirat';
      case statusList.open:
        return 'Nefinalizat';
      case statusList.canceled:
        return 'Anulat';
      case statusList.confirmed:
        return 'Confirmat';
      case statusList.done:
        return 'Finalizat';
      default:
        return 'Nedefinit';
    }
  }

  getPaymentMethodText(method: number) {
    const paymentMethods = this.envService.getValue('paymentMethod');
    switch (Number(method)) {
      case paymentMethods.trainCash:
        return PaymentMethod.trainCash;
      case paymentMethods.onlineCard:
        return PaymentMethod.onlineCard;
      case paymentMethods.trainCard:
        return PaymentMethod.trainCard;
      case paymentMethods.partner:
        return PaymentMethod.partner;
      default:
        return '';
    }
  }

  isExpired(subscription: Subscription) {
    return new Date(subscription.valid_to).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  }

  subscriptionTitle(subscription: Subscription) {
    return `Abonament Nr. ${subscription.id} ${subscription.valid_for == 30 ? '(lunar)' : `(${subscription.valid_for} zile)`}`;
  }

  canShowSubscriptionPaymentButton(subscription: Subscription) {
    return subscription.status == this.envService.getValue('status').open &&
      subscription.payment_status == null &&
      subscription.price > 0 &&
      !this.isExpired(subscription);
  }

  canShowSubscriptionBuySeatButton(subscription: Subscription) {
    return subscription.status == this.envService.getValue('status').done &&
      !this.isExpired(subscription);
  }

  stringToTime(time: string | null) {
    if (time == null) {
      return new Date(0, 0, 0, 0, 0, 0);
    }
    const parts = time.split(':');
    return new Date(0, 0, 0, Number(parts[0]), Number(parts[1]), 0);
  }

  computeTripMask(originPos: number, destinationPos: number) {
    if (originPos > destinationPos) {
      return 0;
    }

    let mask = 0;
    for (let i = originPos; i < destinationPos; i++) {
      mask |= Math.pow(2, i - 1);
    }

    return mask;
  }

  computeVat(user: AuthUser | null, price: number) {
    const vatRate = Number(user?.session?.vatRate || 0);
    return (vatRate * price) / (100 + vatRate);
  }

  round2(number: number) {
    return Math.round((number * 100)) / 100;
  }

  toDbDate(date: Date | null) {
    return this.datePipe.transform(date, 'yyyy-MM-dd') || '';
  }

  toDbDateTime(date: Date | null) {
    return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss') || '';
  }

  seatPlacesDisplay(value: string) {
    return value !== null && value !== '' ? value.replace(/-/g, '/').split(',').join(', ') : '';
  }

  uniqueByProperty(items: any[], property: string): any[] {
    let uniqueItems = new Set();
    return items.filter((item) => {
      if (!uniqueItems.has(item[property])) {
        uniqueItems.add(item[property]);
        return true;
      }
      return false;
    });
  }
}
