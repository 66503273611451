{
  "name": "abonamente",
  "version": "1.1.7",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "prebuild": "npm version patch",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "cypress:open": "cypress open",
    "cypress:run": "cypress run"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.0.5",
    "@angular/cdk": "^18.0.5",
    "@angular/common": "^18.0.5",
    "@angular/compiler": "^18.0.5",
    "@angular/core": "^18.0.5",
    "@angular/flex-layout": "^15.0.0-beta.42",
    "@angular/forms": "^18.0.5",
    "@angular/material": "^18.0.5",
    "@angular/material-moment-adapter": "^18.0.6",
    "@angular/platform-browser": "^18.0.5",
    "@angular/platform-browser-dynamic": "^18.0.5",
    "@angular/router": "^18.0.5",
    "@types/node": "^22.0.2",
    "cypress": "^13.15.0",
    "crypto-js": "^4.2.0",
    "ng-qrcode": "^18.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.0.6",
    "@angular/cli": "^18.0.6",
    "@angular/compiler-cli": "^18.0.5",
    "@types/crypto-js": "^4.2.2",
    "@types/jasmine": "~5.1.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.4.2"
  }
}
