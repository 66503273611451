export const prodEnvironment = {
  appVersion: require('../../package.json').version,
  production: true,
  apiUrl: 'https://api.softrans.ro/api/v1',
  paymentUrl: 'https://secure.mobilpay.ro',
  contentHash: "soyUshlcjtJZ8LQVqu4/ObCykgpFN2EUmfoESVaReiE=",
  afterLoginRedirect: '/home',
  status: {
    expired: -1,
    stalled: 0,
    draft: 1,
    open: 2,
    canceled: 3,
    confirmed: 4,
    done: 5,
    fail: 6
  },
  paymentStatus: {
    open: 'open',
    confirmed: 'confirmed',
    canceled: 'canceled',
    pending: 'pending',
    partialyRefunded: 'partialy_refunded',
    refunded: 'refunded',
    rejected: 'rejected'
  },

  paymentMethod: {
    trainCash: 1,
    onlineCard: 2,
    trainCard: 3,
    partner: 4
  },

  priceListType: {
    distance: 1,
    seat: 2,
    bicycle: 3,
    subscription5: 5,
    subscription14: 14,
    subscription30: 30
  },

  ticketType: {
    standing: 1,
    sitting: 2,
    bicycle: 3
  },

  invoiceChoices: {
    no_invoice: 0,
    natural_person: 1,
    juridic_person: 2
  },

  fullPriceDiscountTypeId: 4,
  bicycleDiscountTypeId: 12,

  maxPlacesCard: 6,
  maxSeatPlaces: 183,
  maxStadingPlaces: 60,
  maxBicyclePlaces: 4,

  minTimeToDeparture: 15,

  defaultItemsPerPage: 10,

  contact: {
    mobile: '0742.018.798',
    email: 'info@softrans.ro'
  },

};
